import { userApi } from '@beelday/common'
import { Config } from 'common/config'
import { RootState } from 'common/redux'
import {
	PROFILE_MISSING,
	SpotlightChange,
	UserId,
	UserProfile,
} from 'common/types'
import { map, noop } from 'lodash'
import { FC, useCallback, useEffect, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useSelector as useSelect } from 'app-redux'
import { useAssertedWorkflowUser } from 'room/common/use-workflow-user'
import { useProfileFor, useProfilesFor } from 'users/redux'
import useFetchMissingProfiles from 'users/use-fetch-missing-profiles'
import { EmbeddedVCR } from './embedded-vcr'
import { EmbeddedJunoVCR } from './embedded-vcr-juno'
import { toVideoUsers } from './model/converters'
import {
	RemoteUserStream,
	RemoteUserStreamStatus,
	VideoUser,
} from './model/types'
import AudioVideoScene from './model/audio-video-scene'
import { setRemoteUserStreams, setSceneVideoUsers } from './redux'
import { selectTraining } from 'session-info/redux'
import { selectAllUsers } from 'room/common/redux'
import { useJuno } from 'vcr/juno/juno-provider'

type Props = {
	presenterId?: UserId
	spotlightPresenter: boolean
}

const toOptionalVideoUser = (
	userId: string,
	userName: string,
	status: RemoteUserStreamStatus,
	stream?: MediaStream
): VideoUser | undefined => {
	if (!stream) {
		return undefined
	}
	return { userId, userName, stream: stream, status }
}

type AudioVideoSceneHook = {
	addSpotlight: (userId: string) => void
	removeSpotlight: (userId: string) => void
}

const useAudioVideoScene = (
	localMediaStream: MediaStream | undefined,
	remoteStreams: RemoteUserStream[],
	props: Props,
	roomUsers: Array<UserProfile | PROFILE_MISSING>,
	userId: UserId,
	userProfile: UserProfile | undefined | PROFILE_MISSING,
	muted: boolean,
	workflowId?: string,
	trainerId?: string
): AudioVideoSceneHook => {
	const dispatch = useDispatch()
	const { juno } = useJuno()
	const users = useSelector(selectAllUsers)
	const scene = useMemo(() => {
		return new AudioVideoScene(
			{
				presenterDelay: Config.videoSpotlightDelayMs,
				trainerId: trainerId,
			},
			async (userId: string, withVideo: boolean) => {
				console.log(`reconfiguring video for user TO ${withVideo}`, userId)
				juno?.reconfigureVideoForUser(userId, withVideo)
			},
			(activeUsers: unknown[]) => {
				sessionStorage.setItem(
					`${workflowId}_activeUsers`,
					JSON.stringify(activeUsers)
				)
			},
			() => {
				return JSON.parse(
					sessionStorage.getItem(`${workflowId}_activeUsers`) || '[]'
				)
			},
			() => {
				const { sceneUsers } = scene.allUsers

				sceneUsers.forEach(u => {
					if (
						!u.isOnScene &&
						!u.presenter &&
						u.stream &&
						u.stream?.getVideoTracks().length > 0 &&
						u.status.video !== 'RENEGOTIATING'
					) {
						console.log('reconfiguring video for user TO FALSE', u.userId)
						juno?.reconfigureVideoForUser(u.userId, false)
					} else if (
						(u.isOnScene || u.presenter) &&
						u.stream &&
						u.stream?.getVideoTracks().length === 0 &&
						u.status.video !== 'RENEGOTIATING'
					) {
						juno?.reconfigureVideoForUser(u.userId, true)
					}
				})
				dispatch(setSceneVideoUsers(sceneUsers))
				// dispatch(setOutsideSceneAudioUsers(outsideSceneUsers))
			}
		)
	}, [dispatch, juno, trainerId, workflowId])

	const withoutMe = useMemo(
		() => roomUsers.filter(u => u && u.id !== userId) as UserProfile[],
		[roomUsers, userId]
	)

	const remoteVideoUsers = useMemo(
		() => toVideoUsers(remoteStreams, withoutMe),
		[withoutMe, remoteStreams]
	)

	const userName = userApi.getDisplayName(userProfile)

	useEffect(() => {
		const { sceneUsers } = scene.setProps({
			fixedPresenterId: props.presenterId,
			localUser: toOptionalVideoUser(
				userId,
				userName || '',
				{ video: 'READY', audio: 'READY' },
				localMediaStream
			),
			spotlightPresenter: props.spotlightPresenter,
		})

		dispatch(setSceneVideoUsers(sceneUsers))
	}, [
		dispatch,
		localMediaStream,
		props.presenterId,
		props.spotlightPresenter,
		userId,
		userName,
		scene,
	])

	useEffect(() => {
		const { sceneUsers } = scene.setProps({
			fixedPresenterId: props.presenterId,
			localUser: toOptionalVideoUser(
				userId,
				userName || '',
				{ video: 'READY', audio: 'READY' },
				localMediaStream
			),
			spotlightPresenter: props.spotlightPresenter,
		})

		dispatch(setSceneVideoUsers(sceneUsers))
	}, [
		dispatch,
		localMediaStream,
		props.presenterId,
		props.spotlightPresenter,
		userId,
		userName,
		scene,
	])

	useEffect(() => scene.setUsers(users), [scene, users])

	useEffect(() => {
		const { sceneUsers } = scene.setRemoteUsers(remoteVideoUsers)

		sceneUsers.forEach(u => {
			console.log('USER', u)

			if (
				u.status.video !== 'RENEGOTIATING' &&
				!u.isOnScene &&
				!u.presenter &&
				u.stream &&
				u.stream?.getVideoTracks().length > 0 &&
				!u.stream.screenShareTrackId
			) {
				console.log('reconfiguring video for user TO FALSE', u.userId)
				juno?.reconfigureVideoForUser(u.userId, false)
			} else if (
				u.status.video !== 'RENEGOTIATING' &&
				(u.isOnScene || u.presenter) &&
				(u.stream?.getVideoTracks().length === 0 ||
					u.stream?.screenShareTrackId)
			) {
				juno?.reconfigureVideoForUser(u.userId, true)
			}
		})

		dispatch(setSceneVideoUsers(sceneUsers))
	}, [dispatch, juno, remoteVideoUsers, scene])

	useEffect(() => {
		const { sceneUsers } = scene.setSceneMuted(muted)

		sceneUsers.forEach(u => {
			if (
				u.status.video !== 'RENEGOTIATING' &&
				!u.isOnScene &&
				!u.presenter &&
				u.stream &&
				u.stream?.getVideoTracks().length > 0 &&
				!u.stream.screenShareTrackId
			) {
				console.log('reconfiguring video for user TO FALSE', u.userId)
				juno?.reconfigureVideoForUser(u.userId, false)
			} else if (
				u.status.video !== 'RENEGOTIATING' &&
				(u.isOnScene || u.presenter) &&
				u.stream?.getVideoTracks().length === 0
			) {
				juno?.reconfigureVideoForUser(u.userId, true)
			}
		})

		dispatch(setSceneVideoUsers(sceneUsers))
	}, [dispatch, juno, muted, scene])

	const addSpotlight = useCallback(
		(userId: string) => {
			scene.addSpotlight(userId)
		},
		[scene]
	)

	const removeSpotlight = useCallback(
		(userId: string) => {
			scene.removeSpotlight(userId)
		},
		[scene]
	)

	return {
		addSpotlight,
		removeSpotlight,
	}
}

export const AudioVideoConferenceMedia: FC<Props> = props => {
	const dispatch = useDispatch()
	const workflowUser = useAssertedWorkflowUser()
	const localMediaStream = useSelector(
		(state: RootState) => state.videoConferenceMedia.localUserStream
	)

	const training = useSelect(state => selectTraining(state.sessionInfo))
	const trainerId = training?.upperEchelon.userId
	const workflowId = training?.liveSessionId

	const roomUsers = useSelector(
		(state: RootState) => map(state.room.users, 'id'),
		shallowEqual
	)
	const workflowUserProfile = useProfileFor(workflowUser.id)
	const roomProfiles = useProfilesFor(roomUsers)
	const remoteStreams = useSelector(
		(state: RootState) => state.videoConferenceMedia.remoteUserStreams
	)
	const mutedVcrScene = useSelector(
		(state: RootState) => state.videoConferenceMedia.mutedVcrScene
	)

	useFetchMissingProfiles([...roomUsers, workflowUser.id])

	const { addSpotlight, removeSpotlight } = useAudioVideoScene(
		localMediaStream,
		remoteStreams,
		props,
		roomProfiles,
		workflowUser.id,
		workflowUserProfile,
		mutedVcrScene,
		workflowId,
		trainerId
	)

	const handleSpotlightChange = useCallback(
		(change: SpotlightChange) => {
			if (change.spotlight) {
				addSpotlight(change.userId)
			} else {
				removeSpotlight(change.userId)
			}
		},
		[addSpotlight, removeSpotlight]
	)

	const handleRemoteStreams = useCallback(
		streams => {
			dispatch(setRemoteUserStreams(streams))
		},
		[dispatch]
	)

	return Config.vcrManager === 'vcr' ? (
		<EmbeddedVCR
			userId={workflowUser.id}
			onLocalMediaStream={noop}
			onRemoteStreams={handleRemoteStreams}
			onSpotlightChange={handleSpotlightChange}
		/>
	) : (
		<EmbeddedJunoVCR
			userId={workflowUser.id}
			onRemoteStreams={handleRemoteStreams}
			onSpotlightChange={handleSpotlightChange}
		/>
	)
}
