import { arrays } from '@beelday/common'
import type { UserId } from 'common/types'
import type { SceneVideoUser, VideoUser } from './types'

export type SceneProps = {
	fixedPresenterId?: UserId
	localUser?: VideoUser
	spotlightPresenter: boolean
}

const equals = (left: any, right: any): boolean =>
	!Object.entries(left).find(e => !(e[0] in right) || right[e[0]] !== e[1])

type SpotlightUser = {
	userId: UserId
	startedTalking: number
	stoppedTalking?: number
}

export type Config = {
	presenterDelay: number
	presenterCloseDelay: number
}

const DEFAULT_CONFIG = {
	presenterDelay: 3000,
	presenterCloseDelay: 6000,
}

export default class VideoScene {
	private presenterDelay: number
	private presenterCloseDelay: number
	private spotlightChangeTimeout?: NodeJS.Timeout
	private onSpotlightChange?: (spotlight: UserId | undefined) => unknown
	private talkingUsers: Map<UserId, SpotlightUser> = new Map()
	private spotlightUser?: SpotlightUser
	private props: SceneProps | undefined
	private remoteUsers: VideoUser[] = []
	private muted = false
	private cachedSceneVideoUsers: Record<UserId, SceneVideoUser> = {}

	constructor(
		options: Partial<Config>,
		onSpotlightChange?: (spotlight: UserId | undefined) => unknown
	) {
		const config = { ...DEFAULT_CONFIG, ...options }
		this.onSpotlightChange = onSpotlightChange
		this.presenterDelay = config.presenterDelay
		this.presenterCloseDelay = config.presenterCloseDelay
	}

	private handleSpotlightChange(): void {
		if (this.isSpotlightQuietFor(this.presenterDelay)) {
			const longestTalking = arrays.minBy(
				this.talkingUsers.values(),
				u => u.startedTalking
			)
			if (longestTalking) {
				this.spotlightUser = longestTalking
			} else if (this.isSpotlightQuietFor(this.presenterCloseDelay)) {
				this.spotlightUser = undefined
			} else {
				//Wait few more seconds before removing spotlight if everyone is quiet
				setTimeout(() => this.handleSpotlightChange(), this.presenterCloseDelay)
			}
		}
		this.onSpotlightChange?.(this.spotlightUser?.userId)
	}

	addSpotlight = (userId: UserId): void => {
		const current = this.talkingUsers.get(userId)
		if (current) {
			if (current.stoppedTalking) {
				current.stoppedTalking = undefined
			}
		} else {
			this.talkingUsers.set(userId, {
				userId: userId,
				startedTalking: Date.now(),
			})
		}

		if (this.spotlightUser?.userId === userId) {
			this.spotlightUser.stoppedTalking = undefined
		}

		this.handleSpotlightChange()
	}

	private isSpotlightQuietFor(ms: number): boolean {
		if (!this.spotlightUser) return true

		return this.spotlightUser.stoppedTalking
			? this.spotlightUser.stoppedTalking <= Date.now() - ms
			: false
	}

	removeSpotlight = (userId: UserId): void => {
		if (this.talkingUsers.delete(userId)) {
			if (this.spotlightUser?.userId === userId) {
				this.spotlightUser.stoppedTalking = Date.now()
				if (this.spotlightChangeTimeout) {
					clearTimeout(this.spotlightChangeTimeout)
				}
				this.spotlightChangeTimeout = setTimeout(
					() => this.handleSpotlightChange(),
					this.presenterDelay
				)
			}

			this.handleSpotlightChange()
		}
	}

	setProps = (props: SceneProps): SceneVideoUser[] => {
		this.props = props
		return this.sceneVideoUsers
	}

	setRemoteUsers = (remoteUsers: VideoUser[]): SceneVideoUser[] => {
		this.remoteUsers = remoteUsers
		return this.sceneVideoUsers
	}

	setSceneMuted = (muted: boolean): SceneVideoUser[] => {
		this.muted = muted
		return this.sceneVideoUsers
	}

	private isPresenter = (userId: UserId) =>
		this.presenterUserId === userId &&
		this.presenterUserId !== this.props?.localUser?.userId

	private isTalking = (userId: string) => this.talkingUsers.has(userId)

	private toRemoteSceneVideoUser = (videoUser: VideoUser): SceneVideoUser => {
		return {
			...videoUser,
			spotlight: this.isTalking(videoUser.userId),
			presenter: this.isPresenter(videoUser.userId),
			muted: this.muted,
			isSelfView: false,
			isOnScene: true,
		}
	}

	private toLocalSceneVideoUser = (videoUser: VideoUser): SceneVideoUser => {
		return {
			...videoUser,
			spotlight: this.isTalking(videoUser.userId),
			presenter: this.isPresenter(videoUser.userId),
			muted: true,
			isSelfView: true,
			isOnScene: true,
		}
	}

	private get recalculatedSceneVideoUsers(): SceneVideoUser[] {
		if (!this.props) {
			return []
		}

		const remoteSceneVideoUsers = this.remoteUsers.map(
			this.toRemoteSceneVideoUser
		)
		if (this.props.localUser) {
			return [
				...remoteSceneVideoUsers,
				this.toLocalSceneVideoUser(this.props.localUser),
			]
		}
		return remoteSceneVideoUsers
	}

	get sceneVideoUsers(): SceneVideoUser[] {
		const current = this.recalculatedSceneVideoUsers
		return current.map(user => {
			const cached = this.cachedSceneVideoUsers[user.userId]
			if (!cached || !equals(user, cached)) {
				this.cachedSceneVideoUsers[user.userId] = user
				return user
			}
			return cached
		})
	}

	private get presenterUserId() {
		if (!this.props) return undefined

		const { fixedPresenterId, spotlightPresenter } = this.props
		const availableUserIds = this.remoteUsers.map(user => user.userId)

		if (fixedPresenterId && availableUserIds.includes(fixedPresenterId)) {
			return fixedPresenterId
		}

		if (spotlightPresenter) {
			return this.spotlightUser?.userId
		}
	}
}
