import { FeatureToggle, hooks, ui } from '@beelday/common'
import styled from '@emotion/styled'
import { VideoColumns } from 'room/group-room/presentation/video-columns'
import { useEffect, useRef } from 'react'
import { SceneVideoUser } from 'video-conference-media'
import { useRemoteControl } from 'features/remote-control'
import { Config } from 'common/config'
import { OffSceneAudioStream } from 'video-conference-media/presentation/off-scene-audio-stream'

const TwoColumnGrid = styled.div`
	display: flex;
	flex: 1;
	height: 100%;
	min-height: 0;
	width: 100%;
`

const ColumnLayout = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	width: 100%;
	margin-top: 20px;

	${ui.responsive.tablet} {
		flex: 0 0 300px;
		margin-left: 30px;
		margin-top: 0;
	}

	${ui.responsive.desktop} {
		flex: 0 0 400px;
	}
`

const ScreenContainer = styled.div<{ remoteControl?: boolean }>`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	video {
		flex-shrink: 1;
		max-width: 100%;
		max-height: 100%;
	}
	border: ${({ remoteControl }) => (remoteControl ? 'solid red 3px' : 'none')};
`

type Props = {
	streams: SceneVideoUser[]
	screen?: MediaStream
	local?: boolean
}

const ScreenShare = ({ streams, screen, local }: Props): JSX.Element => {
	const videoRef = useRef<HTMLVideoElement>(null)
	const remoteControl = useRemoteControl()
	const { setVideoElement } = remoteControl

	const videoUsers = streams.filter(stream => stream.isOnScene)
	const audioUsers = streams.filter(stream => !stream.isOnScene)

	useEffect(() => {
		if (videoRef.current && screen) {
			videoRef.current.srcObject = screen
		}
	}, [screen, videoRef])

	useEffect(() => {
		setVideoElement(videoRef.current || null)
	}, [setVideoElement, videoRef])

	return (
		<TwoColumnGrid style={{ position: 'relative' }}>
			<ScreenContainer remoteControl={remoteControl.state === 'ON'}>
				<video ref={videoRef} autoPlay />
			</ScreenContainer>
			<ColumnLayout>
				<VideoColumns sceneVideoUsers={videoUsers} />
				{audioUsers.map(au => (
					<OffSceneAudioStream key={au.userId} userId={au.userId} />
				))}
			</ColumnLayout>
			<FeatureToggle name="REMOTE_CONTROL">
				{() => {
					return local ? null : (
						<>
							<hooks.OnLifecycle onUnmount={remoteControl.disconnect} />
							<ui.Button
								style={{ position: 'absolute', cursor: 'pointer', zIndex: 99 }}
								onClick={() => {
									if (remoteControl.state === 'ON') {
										remoteControl.disconnect()
									} else {
										remoteControl.connect(Config.remoteControlUrl, 'the token')
									}
								}}
							>
								Remote Control {remoteControl.state === 'ON' ? '(ON)' : ''}
							</ui.Button>
						</>
					)
				}}
			</FeatureToggle>
		</TwoColumnGrid>
	)
}

export default ScreenShare
