import { UserId } from '@beelday/common'

export class BeeldayMediaStream extends MediaStream {
	screenShareTrackId?: string

	constructor(tracks: MediaStreamTrack[]) {
		super(tracks)
	}
}

export type RemoteUserTrackStatus = 'READY' | 'RENEGOTIATING' | 'NOT_READY'
export type RemoteUserStreamStatus = {
	video: RemoteUserTrackStatus
	audio: RemoteUserTrackStatus
}

export type RemoteUserStream = {
	feedId: string
	userId: UserId
	stream: BeeldayMediaStream
	janusId: string
	status: RemoteUserStreamStatus
}

export type VideoUser = {
	stream?: BeeldayMediaStream
	userId: UserId
	userName?: string
	status: RemoteUserStreamStatus
}

export type LocalMediaStream = {
	stream: MediaStream
}

export type SceneVideoUser = VideoUser & {
	spotlight: boolean
	presenter: boolean
	muted: boolean
	videoMuted?: boolean
	isSelfView: boolean
	isOnScene?: boolean
	status: RemoteUserStreamStatus
}

export type VideoStreamMedia = {
	stream: MediaStream
	muted: boolean
}
