import { userApi } from '@beelday/common'
import { Config } from 'common/config'
import { RootState } from 'common/redux'
import {
	PROFILE_MISSING,
	SpotlightChange,
	UserId,
	UserProfile,
} from 'common/types'
import { map, noop } from 'lodash'
import { FC, useCallback, useEffect, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useAssertedWorkflowUser } from 'room/common/use-workflow-user'
import { useProfileFor, useProfilesFor } from 'users/redux'
import useFetchMissingProfiles from 'users/use-fetch-missing-profiles'
import { EmbeddedVCR } from './embedded-vcr'
import { EmbeddedJunoVCR } from './embedded-vcr-juno'
import { toVideoUsers } from './model/converters'
import {
	RemoteUserStream,
	RemoteUserStreamStatus,
	VideoUser,
} from './model/types'
import VideoScene from './model/video-scene'
import { setRemoteUserStreams, setSceneVideoUsers } from './redux'

type Props = {
	presenterId?: UserId
	spotlightPresenter: boolean
}

const toOptionalVideoUser = (
	userId: string,
	userName: string,
	status: RemoteUserStreamStatus,
	stream?: MediaStream
): VideoUser | undefined => {
	if (!stream) {
		return undefined
	}
	return { userId, userName, stream: stream, status }
}

type VideoSceneHook = {
	addSpotlight: (userId: string) => void
	removeSpotlight: (userId: string) => void
}

const useVideoScene = (
	localMediaStream: MediaStream | undefined,
	remoteStreams: RemoteUserStream[],
	props: Props,
	roomUsers: Array<UserProfile | PROFILE_MISSING>,
	userId: UserId,
	userProfile: UserProfile | undefined | PROFILE_MISSING,
	muted: boolean
): VideoSceneHook => {
	const dispatch = useDispatch()
	const scene = useMemo(
		() =>
			new VideoScene(
				{
					presenterDelay: Config.videoSpotlightDelayMs,
				},
				() => {
					dispatch(setSceneVideoUsers(scene.sceneVideoUsers))
				}
			),
		[dispatch]
	)

	const withoutMe = useMemo(
		() => roomUsers.filter(u => u && u.id !== userId) as UserProfile[],
		[roomUsers, userId]
	)

	const remoteVideoUsers = useMemo(
		() => toVideoUsers(remoteStreams, withoutMe),
		[withoutMe, remoteStreams]
	)

	const userName = userApi.getDisplayName(userProfile)

	useEffect(() => {
		const sceneVideoUsers = scene.setProps({
			fixedPresenterId: props.presenterId,
			localUser: toOptionalVideoUser(
				userId,
				userName || '',
				{ video: 'READY', audio: 'READY' },
				localMediaStream
			),
			spotlightPresenter: props.spotlightPresenter,
		})
		dispatch(setSceneVideoUsers(sceneVideoUsers))
	}, [
		dispatch,
		localMediaStream,
		props.presenterId,
		props.spotlightPresenter,
		userId,
		userName,
		scene,
	])

	useEffect(() => {
		dispatch(setSceneVideoUsers(scene.setRemoteUsers(remoteVideoUsers)))
	}, [dispatch, remoteVideoUsers, scene])

	useEffect(() => {
		dispatch(setSceneVideoUsers(scene.setSceneMuted(muted)))
	}, [dispatch, muted, scene])

	const addSpotlight = useCallback(
		(userId: string) => {
			scene.addSpotlight(userId)
		},
		[scene]
	)

	const removeSpotlight = useCallback(
		(userId: string) => {
			scene.removeSpotlight(userId)
		},
		[scene]
	)

	return {
		addSpotlight,
		removeSpotlight,
	}
}

export const VideoConferenceMedia: FC<Props> = props => {
	const dispatch = useDispatch()
	const workflowUser = useAssertedWorkflowUser()
	const localMediaStream = useSelector(
		(state: RootState) => state.videoConferenceMedia.localUserStream
	)
	const roomUsers = useSelector(
		(state: RootState) => map(state.room.users, 'id'),
		shallowEqual
	)
	const workflowUserProfile = useProfileFor(workflowUser.id)
	const roomProfiles = useProfilesFor(roomUsers)
	const remoteStreams = useSelector(
		(state: RootState) => state.videoConferenceMedia.remoteUserStreams
	)
	const mutedVcrScene = useSelector(
		(state: RootState) => state.videoConferenceMedia.mutedVcrScene
	)

	useFetchMissingProfiles([...roomUsers, workflowUser.id])

	const { addSpotlight, removeSpotlight } = useVideoScene(
		localMediaStream,
		remoteStreams,
		props,
		roomProfiles,
		workflowUser.id,
		workflowUserProfile,
		mutedVcrScene
	)

	const handleSpotlightChange = useCallback(
		(change: SpotlightChange) => {
			if (change.spotlight) {
				addSpotlight(change.userId)
			} else {
				removeSpotlight(change.userId)
			}
		},
		[addSpotlight, removeSpotlight]
	)

	const handleRemoteStreams = useCallback(
		streams => dispatch(setRemoteUserStreams(streams)),
		[dispatch]
	)

	return Config.vcrManager === 'vcr' ? (
		<EmbeddedVCR
			userId={workflowUser.id}
			onLocalMediaStream={noop}
			onRemoteStreams={handleRemoteStreams}
			onSpotlightChange={handleSpotlightChange}
		/>
	) : (
		<EmbeddedJunoVCR
			userId={workflowUser.id}
			onRemoteStreams={handleRemoteStreams}
			onSpotlightChange={handleSpotlightChange}
		/>
	)
}
