import {
	BeeldayMediaStream,
	RemoteUserStream,
	RemoteUserStreamStatus,
} from 'video-conference-media/model/types'
import { Config } from 'common/config'
import Logger from 'common/logger'
import Janus from './janus.v1.1.1'
import RtcMetrics from '../rtc-metrics'

import {
	DeleteRemoteUserStreamObserver,
	NewRemoteUserStreamObserver,
	RemoteUserStreamsObserver,
	SpotlightChangeObserver,
} from './juno-vcr'

import { logger } from '@beelday/common'
import { difference, update } from 'lodash'
import { ca } from 'date-fns/locale'
import { error, success } from '@beelday/common/src/images'

const log = logger.create('janus')

const user = (userId?: string) => String(userId).substring(0, 8)
export const shortJanusName = (janusUrl: string): string | undefined =>
	janusUrl ? /(\d+)/.exec(janusUrl)?.[0] : 'unknown'

const getInstanceLogger = (roomId: string, janusId: string) =>
	new Logger(`juno[${roomId}]-janus[${shortJanusName(janusId)}]-instance`)

const getPublishLogger = (roomId: string, janusId: string) =>
	new Logger(`juno[${roomId}]-janus[${shortJanusName(janusId)}]-local-plugin`)

const getSubscriberLogger = (roomId: string, janusId: string, userId: string) =>
	new Logger(
		`juno[${roomId}]-janus[${shortJanusName(janusId)}]-subscribe[${user(
			userId
		)}]-plugin`
	)

type UserId = string
type FeedId = string
type JanusRoomId = string
type JanusTrackEventMetadata = {
	reason: 'created' | 'ended' | 'mute' | 'unmute'
}

type Track = {
	id: string
	mid: string
	label: string
	type: string
}

type PluginHandle = {
	getId: () => string
	send: (payload: any) => void
	createOffer: (payload: any) => void
	createAnswer: (payload: any) => void
	replaceTracks: (payload: any) => void
	handleRemoteJsep: (payload: { jsep: any }) => void
	hangup: () => void
	detach: () => void
	webrtcStuff?: any
	getLocalTracks: () => Track[]
}

type Publisher = {
	id: string | number
	display: string
	audio_codec: string
	video_codec: string
	simulcast: boolean
	talking: boolean
}

type Message = {
	id: number
	room: JanusRoomId
	videoroom: string
	error?: string
	error_code?: number
	publishers?: Publisher[]
	leaving?: FeedId
	unpublished?: FeedId | 'ok'
	started?: 'ok'
	configured?: 'ok'
	audio_codec?: string
	video_codec?: string
	private_id: string
	streams?: SubscriberStream[]
}

type SubscriberStream = {
	type: 'audio' | 'video' | 'data'
	mid: string
	feed_description?: string
}

export type JanusDefinition = {
	janusNodeUrl: string
	janusVcrId: JanusRoomId
	janusUserId: string
}

type Callback = (...args: any[]) => any

type NodeState = 'connecting' | 'subscribed' | 'publishing' | 'disconnecting'

const SCREEN_SHARE = 'SCREEN_SHARE'

export class JanusNode {
	private privateId: string | undefined = undefined
	private opaqueId = `vcr-oid-${Janus.randomString(12)}`
	private remoteFeeds: Record<UserId, PluginHandle> = {}
	private remoteStreams: Record<FeedId, RemoteUserStream> = {}
	private localFeed: PluginHandle | undefined

	private remoteUserStreamObserver: RemoteUserStreamsObserver | undefined
	private spotlightChangeObserver: SpotlightChangeObserver | undefined
	private newRemoteUserStreamObserver: NewRemoteUserStreamObserver | undefined
	private deleteRemoteUserStreamObserver:
		| DeleteRemoteUserStreamObserver
		| undefined
	private mediaStatsCheckInterval: number | undefined
	private rtcMetrics = new RtcMetrics()
	private bitrate: number | undefined = undefined
	private reconfigureRequest: { bitrate: number | undefined } | undefined
	private localStream?: MediaStream
	private localScreenSharingTrackId?: string

	private readonly instanceLogger = new Logger('janus')

	private subscribeCallback?: Callback
	private subscribeFailedCallback?: Callback
	private publishCallback?: Callback
	private publishFailedCallback?: Callback
	private unpublishCallback?: Callback
	private unpublishFailedCallback?: Callback
	private disconnectCallback?: Callback

	private state: NodeState = 'connecting'
	private janus?: any

	constructor(
		private janusDefinition: JanusDefinition,
		private myUserId: UserId,
		private onFatalError: Callback
	) {
		this.instanceLogger = getInstanceLogger(
			janusDefinition.janusVcrId,
			janusDefinition.janusNodeUrl
		)
	}

	public async connect(onFatalError: Callback): Promise<Janus> {
		this.janus = await new Promise((resolve, reject) => {
			const janus = new Janus({
				server: `${this.janusDefinition.janusNodeUrl}`,
				success: () => {
					resolve(janus)
				},
				error: (error: any) => {
					if (error === 'Lost connection to the server (is it down?)') {
						onFatalError()
					}
					log.error(error)
					reject('Initializing Janus JS API failed')
				},
				destroyed: () => {
					log.log('Janus session has been destroyed')
				},
			})
		})

		return this.janus
	}

	reconnectMissingUsers = (): Promise<void> => {
		if (this.state === 'connecting' || this.state === 'disconnecting') {
			logger.warn("Can't reconnect missing users while Janus is not ready")
			return Promise.resolve()
		}

		return new Promise((resolve, reject) => {
			this.localFeed?.send({
				message: {
					request: 'listparticipants',
					room: this.janusDefinition.janusVcrId,
				},
				error: (error: unknown) => {
					log.error('Failed to check for users in room', error)
					reject(error)
				},
				success: (res: ListParticipantsResponse) => {
					try {
						log.info('Re-checking if all users in room are connected')
						const { participants } = res
						const remoteIds = Object.keys(this.remoteFeeds)
						const participantIds = participants
							.map(p => p.id)
							//Don't try to subscribe to my own stream
							.filter(id => id !== this.janusDefinition.janusUserId)
						const missingUsers = difference(participantIds, remoteIds)
						if (missingUsers.length) {
							log.warn('Found missing participants in room: ', missingUsers)
							missingUsers.forEach(userId => {
								const participant = participants.find(p => p.id === userId)
								if (participant) {
									log.warn('Re-subscribing missing participant: ', participant)
									if (this.remoteFeeds.hasOwnProperty(participant.id)) {
										log.log(
											`Already subscribed to user ${participant.id}, skipping`
										)
									} else {
										this.subscribe(participant)
									}
								}
							})
						} else {
							log.info('All users in room are connected')
						}
						resolve()
					} catch (error) {
						log.error('Failed to reconnect missing users', error)
						reject(error)
					}
				},
			})
		})
	}

	reconfigureTracks = (stream: MediaStream): void => {
		if (this.state === 'connecting' || this.state === 'disconnecting') {
			this.instanceLogger.warn(
				`Ignoring reconfiguration request since Janus is still ${this.state}`
			)
			return
		}

		if (this.state === 'subscribed') {
			this.instanceLogger.log(
				`Ignoring reconfiguration request since user is not publishing to this Janus`
			)
			return
		}

		this.localStream = stream

		const audioTrack = this.localFeed
			?.getLocalTracks()
			.find(track => track.type === 'audio')

		const videoTrack = this.localFeed
			?.getLocalTracks()
			.find(
				track =>
					track.type === 'video' && track.id !== this.localScreenSharingTrackId
			)

		this.localFeed?.replaceTracks({
			tracks: [
				{
					type: 'audio',
					mid: audioTrack?.mid ?? 0,
					capture: this.localStream?.getAudioTracks()[0],
					dontStop: true,
					recv: false,
				},
				{
					type: 'video',
					mid: videoTrack?.mid ?? 0,
					capture: this.localStream?.getVideoTracks()[0],
					simulcast: false,
					dontStop: true,
					recv: false,
				},
			],
		})
	}

	reconfigure = (bitrate: number): void => {
		if (this.state === 'connecting' || this.state === 'disconnecting') {
			this.instanceLogger.warn(
				`Ignoring reconfiguration request since Janus is still ${this.state}`
			)
			return
		}

		if (this.state === 'subscribed') {
			this.instanceLogger.log(
				`Ignoring reconfiguration request since user is not publishing to this Janus`
			)
			return
		}

		if (this.bitrate === bitrate) {
			return
		}

		this.instanceLogger.log(`Reconfigure bitrate: ${bitrate}`)
		this.localFeed?.send({
			message: {
				request: 'configure',
				bitrate,
			},
		})

		this.reconfigureRequest = {
			bitrate,
		}
	}

	reconfigureVideoForUser = (userId: UserId, withVideo: boolean): void => {
		if (this.state === 'connecting' || this.state === 'disconnecting') {
			this.instanceLogger.warn(
				`Ignoring reconfiguration request since Janus is still ${this.state}`
			)
			return
		}

		if (this.state === 'subscribed') {
			this.instanceLogger.log(
				`Ignoring reconfiguration request since user is not publishing to this Janus`
			)
			return
		}

		const remoteFeed = this.remoteFeeds[userId]

		if (remoteFeed) {
			const remoteStream = this.remoteStreams[userId]

			if (remoteStream.status.video === 'RENEGOTIATING') {
				return
			}

			this.remoteStreams[userId] = {
				...remoteStream,
				status: { video: 'RENEGOTIATING', audio: 'READY' },
			}
			this.instanceLogger.log(
				`reconfigure video for user: ${userId} to ${withVideo}`
			)

			remoteFeed.send({
				message: {
					request: 'configure',
					streams: [
						{
							mid: '1',
							send: withVideo,
						},
						{
							mid: '0',
							send: true,
						},
					],
				},
			})
			this.remoteUserStreamObserver?.(Object.values(this.remoteStreams))
		}
	}

	private logStats = () => {
		const localPC = this.localFeed?.webrtcStuff?.pc
		localPC && this.rtcMetrics.capture(this.myUserId, localPC)
		Object.entries(this.remoteFeeds).forEach(([userId, pluginHandle]) => {
			const pc = pluginHandle.webrtcStuff?.pc
			pc && this.rtcMetrics.capture(userId, pc)
		})
	}

	attach = ({
		bitrate,
		localStream,
		successCallback,
		errorCallback,
	}: {
		localStream?: MediaStream
		bitrate?: number
		successCallback?: Callback
		errorCallback?: Callback
	}): void => {
		this.subscribeCallback = successCallback
		this.subscribeFailedCallback = errorCallback
		this.bitrate = bitrate
		this.localStream = localStream

		this.instanceLogger.log(`Attach Local Plugin (subscribe-only mode)`)

		if (this.janus) {
			const publishLogger = getPublishLogger(
				this.janusDefinition.janusVcrId,
				this.janusDefinition.janusNodeUrl
			)
			const failAttach = (error: Error): void => {
				publishLogger.error(error)
				this.subscribeFailedCallback?.(
					'Attaching to Janus thew an error ' + error.message
				)
				delete this.subscribeCallback
				delete this.unpublishFailedCallback
			}

			this.janus.attach({
				plugin: 'janus.plugin.videoroom',
				opaqueId: this.opaqueId,
				success: (pluginHandle: PluginHandle) => {
					this.localFeed = pluginHandle
					log.info(
						'Attached to session/handle: ',
						this.janus.getSessionId(),
						pluginHandle.getId()
					)

					if (!pluginHandle) {
						failAttach(new Error('Failed to connect. No local handle!'))
						return
					}
					publishLogger.log(`Plugin attached for current user`)

					this.localFeed.send({
						message: {
							request: 'join',
							room: String(this.janusDefinition.janusVcrId),
							ptype: 'publisher',
							display: this.myUserId,
							pin: '',
							id: this.janusDefinition.janusUserId,
						},
					})
				},
				error: failAttach,
				webrtcState: (on: boolean): void => {
					if (on) {
						publishLogger.log('WebRTC connection is active')
						if (!this.mediaStatsCheckInterval) {
							this.mediaStatsCheckInterval = window.setInterval(
								this.logStats,
								Config.mediaStatsIntervalMs
							)
						}
					} else {
						publishLogger.log('WebRTC connection is inactive')
						if (this.mediaStatsCheckInterval) {
							window.clearInterval(this.mediaStatsCheckInterval)
						}
					}
				},
				onmessage: (msg: Message, jsep: any): void => {
					if (msg.error_code === 436) {
						if (this.subscribeCallback) {
							failAttach(new Error(`Got 436! ${msg.error}`))
						} else {
							log.error('Got 436 after subscribed! IMPOSSIBLE!!!!')
						}
						return
					}
					if (msg.error) {
						publishLogger.error(
							'Error message received',
							msg.error_code,
							msg.error
						)
						throw new Error(msg.error)
					}

					const { id, room, publishers, videoroom } = msg
					if (msg.configured === 'ok') {
						if (this.reconfigureRequest) {
							this.bitrate = this.reconfigureRequest.bitrate
							this.reconfigureRequest = undefined
						}
					} else if (videoroom === 'joined') {
						const { private_id } = msg

						this.privateId = private_id

						publishLogger.log(`Current user joined room ${room}`)

						this.state = 'subscribed'
						this.subscribeCallback?.()
						delete this.subscribeCallback
						delete this.unpublishFailedCallback
					} else if (videoroom === 'event') {
						const { started, leaving, unpublished, configured } = msg

						if (leaving === 'ok') {
							publishLogger.log('Current user left the room')

							this.janus.destroy({ cleanupHandles: true })
						} else if (leaving) {
							publishLogger.log(`User ${user(leaving)} left the room`)
							this.deleteRemoteStream(leaving)
						} else if (configured === 'ok') {
							publishLogger.log(
								`Current user reconfigured publish stream in room ${room}`
							)
						} else if (configured) {
							publishLogger.log(
								`User ${user(configured)} reconfigured stream in room ${room}`
							)
						} else if (started === 'ok') {
							publishLogger.log(
								`Current user started publishing in room ${room}`
							)
						} else if (started) {
							publishLogger.log(
								`User ${user(started)} started publishing in room ${room}`
							)
						} else if (unpublished === 'ok') {
							publishLogger.log(
								`Current user stopped publishing in room ${room}`
							)
						} else if (unpublished) {
							publishLogger.log(
								`User ${user(unpublished)} stopped publishing in room ${room}`
							)
							this.deleteRemoteStream(unpublished)
						}
					} else if (videoroom === 'talking') {
						this.changeSpotlight(id.toString(), true)
					} else if (videoroom === 'stopped-talking') {
						this.changeSpotlight(id.toString(), false)
					} else {
						publishLogger.warn(`Invalid 'videoroom': ${videoroom}`)
					}

					if (publishers) {
						publishLogger.log(
							`Subscribing to ${publishers.length} new publishers in room ${room}`
						)

						publishers.forEach(this.subscribe)
					}

					if (jsep) {
						publishLogger.log('Received an SDP')
						if (!this.localFeed) {
							throw new Error('Local feed is not available for JSEP response')
						}

						this.localFeed.handleRemoteJsep({ jsep })

						if (!msg.audio_codec) {
							throw new Error('Audio has been rejected!')
						}
						if (!msg.video_codec) {
							throw new Error('Video has been rejected!')
						}
					}
				},

				onlocaltrack: () => {
					// We provide local stream & don't need to handle that from Janus side
				},
				onremotetrack: () => {
					// The publisher stream is sendonly, we don't expect anything here
				},
				oncleanup: () => {
					delete this.localFeed
					if (this.state === 'disconnecting') {
						this.disconnectIfClosed()
					}
				},
			})
		} else {
			throw new Error('Janus not connected. Cannot attach.')
		}
	}

	publish = (successCallback?: Callback, errorCallback?: Callback): void => {
		this.publishCallback = successCallback
		this.publishFailedCallback = errorCallback

		if (this.state === 'connecting') {
			throw new Error('Trying to publish to Janus that has not joined yet')
		}

		if (this.state === 'publishing') {
			this.instanceLogger.log('Already publishing')
			return
		}

		if (this.state === 'disconnecting') {
			this.instanceLogger.warn('Requested to publish on Janus being destroyed')
			return
		}

		return this.sendOffer()
	}

	unpublish = (successCallback?: Callback, errorCallback?: Callback): void => {
		this.unpublishCallback = successCallback
		this.unpublishFailedCallback = errorCallback

		if (!this.localFeed) {
			throw new Error('No local feed available before unpublish()')
		}

		const message = { request: 'unpublish' }

		this.localFeed.send({
			message,
			success: () => {
				this.state = 'subscribed'
				this.unpublishCallback?.()
				delete this.unpublishCallback
				delete this.unpublishFailedCallback
			},
			error: (error: any) => {
				this.instanceLogger.error(error)
				this.unpublishFailedCallback?.('Unpublishing failed')
				delete this.unpublishCallback
				delete this.unpublishFailedCallback
			},
		})
	}

	private sendOffer = () => {
		if (!this.localFeed) {
			this.publishFailedCallback?.(
				'No local feed available before createOffer()'
			)
			delete this.publishCallback
			delete this.publishFailedCallback
			return
		}

		this.instanceLogger.log(
			'Creating SDP offer for publishing the local stream'
		)

		this.localFeed.createOffer({
			tracks: [
				{
					type: 'audio',
					capture: this.localStream?.getAudioTracks()[0],
					dontStop: true,
					recv: false,
				},
				{
					type: 'video',
					capture: this.localStream?.getVideoTracks()[0],
					simulcast: false,
					dontStop: true,
					recv: false,
				},
			],

			success: this.onOfferSuccess,
			error: this.onOfferError,
		})
	}

	startScreenShare = (track: MediaStreamTrack): Promise<void> => {
		this.localScreenSharingTrackId = track.id

		return new Promise((resolve, reject) => {
			this.localFeed?.createOffer({
				tracks: [
					{
						type: 'screen',
						capture: track,
						add: true,
						recv: false,
						dontStop: true,
					},
				],
				success: (jsep: unknown) => {
					this.onOfferSuccess(jsep)
					resolve(undefined)
				},
				error: (err: Error) => {
					delete this.localScreenSharingTrackId
					this.onOfferError(err)
					reject(err)
				},
			})
		})
	}

	stopScreenShare = async (): Promise<void> => {
		const screenSharingTrack = this.localFeed
			?.getLocalTracks()
			.find(t => t.id === this.localScreenSharingTrackId)

		delete this.localScreenSharingTrackId

		return screenSharingTrack
			? new Promise((resolve, reject) => {
					this.localFeed?.createOffer({
						tracks: [
							{
								type: 'screen',
								mid: screenSharingTrack.mid,
								remove: true,
							},
						],
						success: (jsep: unknown) => {
							this.onOfferSuccess(jsep)
							resolve(undefined)
						},
						error: (err: Error) => {
							this.onOfferError(err)
							reject(err)
						},
					})
			  })
			: Promise.resolve()
	}

	onOfferSuccess = (sdp: unknown) => {
		if (!this.localFeed) {
			throw new Error('No local feed available after crateOffer()')
		}

		this.instanceLogger.log(
			'SDP offer for local stream ready. Will send to Janus'
		)

		const localTracks = this.localFeed.getLocalTracks()
		const streamingTrack = localTracks.find(
			t => t.id === this.localScreenSharingTrackId
		)
		this.localFeed.send({
			message: {
				request: 'configure',
				audio: true,
				video: true,
				bitrate: this.bitrate,
				descriptions: streamingTrack
					? [
							{
								mid: streamingTrack.mid,
								description: SCREEN_SHARE,
							},
					  ]
					: undefined,
			},
			jsep: sdp,
			success: () => {
				this.instanceLogger.log(`Publishing with bitrate ${this.bitrate}`)
				this.state = 'publishing'
				this.publishCallback?.()
				delete this.publishCallback
				delete this.publishFailedCallback
			},
			error: (error: any) => {
				this.instanceLogger.error(error)
				this.publishFailedCallback?.(
					'Sending configure request for publishing failed'
				)
				delete this.publishCallback
				delete this.publishFailedCallback
			},
		})
	}

	onOfferError = (error: Error) => {
		this.instanceLogger.error(error)
		this.publishFailedCallback?.('Creating offer failed')
		delete this.publishCallback
		delete this.publishFailedCallback
	}

	private putRemoteStream = (
		feedId: FeedId,
		userId: UserId,
		stream: BeeldayMediaStream,
		status: RemoteUserStreamStatus
	) => {
		const currentStream = this.remoteStreams[feedId]
		const sameStream =
			currentStream?.userId === userId && currentStream.stream === stream

		if (sameStream) {
			log.log(`Same stream for user: ${userId}`)
			return undefined
		}

		const newStream: RemoteUserStream = {
			feedId,
			userId,
			stream,
			janusId: this.janusDefinition.janusNodeUrl,
			status,
		}

		this.instanceLogger.log(`Put a new remote stream from user ${user(userId)}`)
		this.remoteStreams[feedId] = newStream
		this.newRemoteUserStreamObserver?.(newStream)
	}

	private updateRemoteStream = (
		feedId: FeedId,
		userId: UserId,
		stream: BeeldayMediaStream,
		status: RemoteUserStreamStatus
	) => {
		const currentStream = this.remoteStreams[feedId]
		const sameStream =
			currentStream?.userId === userId &&
			currentStream.stream === stream &&
			currentStream.status === status

		if (sameStream) {
			log.log(`Same stream for user: ${userId}`)
			this.remoteUserStreamObserver?.(Object.values(this.remoteStreams))
			return
		}

		const newStream: RemoteUserStream = {
			feedId,
			userId,
			stream,
			janusId: this.janusDefinition.janusNodeUrl,
			status,
		}

		this.instanceLogger.log(
			`Update an existing remote stream from user ${user(userId)}`
		)
		this.remoteStreams[feedId] = newStream
		this.remoteUserStreamObserver?.(Object.values(this.remoteStreams))
	}

	private deleteRemoteStream = (feedId: FeedId) => {
		delete this.remoteStreams[feedId]
		this.deleteRemoteUserStreamObserver?.(feedId)
	}

	private subscribe = (publisher: JanusParticipant) => {
		if (this.state === 'disconnecting') {
			this.instanceLogger.warn(
				'Cannot subscribe to new users while Janus is being destroyed'
			)
			return
		}

		const currentSub = this.remoteFeeds[publisher.id]
		if (currentSub) {
			log.warn("Already subscribed to user's stream, hanging up", publisher.id)
			currentSub.detach()
		}
		const feedId = publisher.id.toString()
		const userId = publisher.display
		const talking = publisher.talking

		const tracks: Map<string, MediaStreamTrack> = new Map()
		let stream: BeeldayMediaStream | null = null
		const remoteStream: RemoteUserStream = this.remoteStreams[feedId]
		const status = remoteStream?.status || {
			audio: 'NOT_READY',
			video: 'NOT_READY',
		}
		// || {
		// 	feedId,
		// 	userId,
		// 	stream: stream,
		// 	janusId: this.janusDefinition.janusNodeUrl,
		// 	status: {
		// 		video: 'READY',
		// 		audio: 'READY',
		// 	},
		// }

		const subLogger = getSubscriberLogger(
			this.janusDefinition.janusVcrId,
			this.janusDefinition.janusNodeUrl,
			userId
		)

		this.instanceLogger.log(`Attach Subscribe Plugin for user ${userId}`)

		let remoteFeed: PluginHandle | null = null
		const remoteScreenShareMid: Set<string> = new Set()

		this.janus.attach({
			plugin: 'janus.plugin.videoroom',
			opaqueId: this.opaqueId,
			success: (pluginHandle: PluginHandle) => {
				if (!this.privateId) {
					throw new Error('Private id is still unavailable!')
				}
				remoteFeed = pluginHandle
				remoteFeed.send({
					message: {
						request: 'join',
						room: '' + this.janusDefinition.janusVcrId,
						ptype: 'subscriber',
						feed: feedId,
						private_id: this.privateId,
						pin: '',
					},
				})
			},
			error: (error: any) => {
				subLogger.error(error)
			},
			onmessage: (msg: Message, jsep: any) => {
				if (msg.error) {
					subLogger.error('Error message received', msg.error_code, msg.error)
					throw new Error(msg.error)
				}

				if (!remoteFeed) {
					subLogger.error('Remote feed is not ready!')
					return
				}

				const event = msg.videoroom

				switch (event) {
					case 'attached':
						subLogger.log(`Subscriber attached, streams: `, msg.streams)
						this.remoteFeeds[userId] = remoteFeed
						const screenStreams =
							msg.streams?.filter(s => s.feed_description === SCREEN_SHARE) ||
							[]
						screenStreams.forEach(s => remoteScreenShareMid.add(s.mid))
						break
					case 'updated':
						subLogger.log(`Subscriber received an update`, event)
						subLogger.log(msg)
						break
					case 'event':
						subLogger.log(`Subscriber received an event`, event.toString())
						subLogger.log(msg)
						if (msg.configured === 'ok' && stream) {
							subLogger.log(`Subscriber configured`, event)
							// this.updateRemoteStream(feedId, userId, stream, {
							// 	video: 'READY',
							// 	audio: 'READY',
							// })
						}
						break
					default:
						subLogger.error(`Invalid "videoroom": ${event}`)
				}

				if (jsep) {
					subLogger.log(`Subscriber sent us an SDP offer, creating answer`)

					remoteFeed.createAnswer({
						jsep,
						media: {
							audioSend: false,
							videoSend: false,
						},
						success: (jsep: any) => {
							if (!remoteFeed) {
								subLogger.error('Remote feed is not ready!')
								return
							}
							subLogger.log(`Answer created. Sending to Janus`)
							remoteFeed.send({
								jsep,
								message: {
									request: 'start',
									room: this.janusDefinition.janusVcrId,
								},
								error: (error: unknown) => {
									subLogger.error(
										'Sending answer for the SDP offer failed, kill connection',
										userId,
										error
									)
									remoteFeed?.detach()
								},
							})
						},
						error: (error: any) => {
							this.instanceLogger.error(
								`Sending answer for the SDP offer errored, kill connection`,
								userId,
								error
							)
							remoteFeed?.detach()
						},
					})
				}
			},
			webrtcState: (on: boolean) => {
				subLogger.log(`WebRTC connection is ${on ? 'active' : 'inactive'}`)
			},
			onlocaltrack: () => {
				// The subscriber stream is recvonly, we don't expect anything here
			},
			onremotetrack: (
				track: MediaStreamTrack,
				mid: string,
				on: boolean,
				metadata?: JanusTrackEventMetadata
			) => {
				if (stream == null) {
					stream = new BeeldayMediaStream([])
				}
				subLogger.log(
					`Remote track - ${mid}/${track.kind} ${on ? 'opened' : 'closed'}`
				)

				//Workaround for: https://groups.google.com/g/meetecho-janus/c/bZaQA4MqJcs
				if (
					(metadata?.reason === 'mute' || metadata?.reason === 'unmute') &&
					remoteScreenShareMid.has(mid)
				) {
					subLogger.warn(
						'Mute/unute on remote screensharing track. Ignoring! (workaround for blinking screen share)'
					)
					return
				}

				if (on) {
					tracks.set(mid, track)
					stream.addTrack(track)
				} else {
					tracks.delete(mid)
					stream.removeTrack(track)
				}

				subLogger.log(`Currently received streams for ${userId}`, tracks)
				if (remoteScreenShareMid.has(mid)) {
					stream.screenShareTrackId = track.id
					subLogger.log(`Screen share track id for ${userId}`, mid, track.id)
				}

				if (tracks.size === 0) {
					subLogger.log("No tracks after 'on remote track', user leaving")
					stream = null
					this.deleteRemoteStream(feedId)
				}
				// else if (on || this.remoteStreams[feedId]) {
				//Add stream only if track was added or we already have this stream.
				//Sometimes we don't get a remove track event for all tracks
				//when user leaves a room.
				//
				//Then we get a late "mute" track event for video tracks due to
				//event.track.onmute event handler in Janus, even though we have
				//already removed whole stream.
				// this.putRemoteStream(feedId, userId, stream)
				// }

				if (stream) {
					if (track.kind === 'video') {
						status.video = 'READY'
					} else if (track.kind === 'audio') {
						status.audio = 'READY'
					}

					if (!this.remoteStreams[feedId]) {
						this.putRemoteStream(feedId, userId, stream, status)
					} else {
						this.updateRemoteStream(feedId, userId, stream, status)
					}
				}
			},
			oncleanup: () => {
				subLogger.log('Remote handle removed')
				tracks.clear()
				stream = null
				delete this.remoteFeeds[userId]
				if (this.state === 'disconnecting') {
					this.disconnectIfClosed()
				}
			},
		})

		this.spotlightChangeObserver?.({
			spotlight: talking,
			userId,
		})
	}

	onRemoteStreamChange = (observer: RemoteUserStreamsObserver): void => {
		this.remoteUserStreamObserver = observer
	}

	onNewRemoteStream = (observer: NewRemoteUserStreamObserver): void => {
		this.newRemoteUserStreamObserver = observer
	}

	onSpotlightChange = (observer: SpotlightChangeObserver): void => {
		this.spotlightChangeObserver = observer
	}

	onUserLeft = (observer: DeleteRemoteUserStreamObserver): void => {
		this.deleteRemoteUserStreamObserver = observer
	}

	disconnect = (callback?: Callback): void => {
		if (this.state === 'disconnecting') {
			this.instanceLogger.warn('Already disconnecting')
			return
		}

		this.instanceLogger.log('Initialize disconnect')
		this.state = 'disconnecting'
		this.disconnectCallback = callback
		this.clearResources()
		this.localFeed?.send({ message: { request: 'leave' } })
	}

	disconnectIfClosed = (): void => {
		if (this.localFeed) {
			this.instanceLogger.log(
				'Local handle still open, postpone disconnect callback'
			)
			return
		}

		if (Object.keys(this.remoteFeeds).length) {
			const handles = Object.values(this.remoteFeeds)
				.map(feed => feed.getId())
				.join(', ')

			this.instanceLogger.log(
				`Some remote handles still open (${handles}), postpone disconnect callback`
			)
			return
		}

		this.instanceLogger.log('Handles cleaned & Janus destroyed')
		this.disconnectCallback?.()
		delete this.disconnectCallback
	}

	private clearResources = () => {
		this.spotlightChangeObserver = undefined
		this.remoteUserStreamObserver && this.remoteUserStreamObserver([])
		clearInterval(this.mediaStatsCheckInterval)
		this.mediaStatsCheckInterval = undefined
	}

	private getUserId = (feedId: FeedId) => {
		return this.remoteStreams[feedId]?.userId
	}

	private changeSpotlight = (feedId: FeedId, spotlight: boolean) => {
		if (feedId === this.janusDefinition.janusUserId.toString()) {
			return
		}
		const userId = this.getUserId(feedId)
		if (!userId) {
			this.instanceLogger.warn(
				`User id ${userId} (feed id: ${feedId}) is not available for a spotlight change`
			)
			return
		}
		this.spotlightChangeObserver?.({ spotlight, userId })
	}
}

const initJanus = (): Promise<void> => {
	return new Promise((resolve, reject) => {
		Janus.init({
			debug: ['warn', 'error'],
			dependencies: Janus.useDefaultDependencies(),
			callback: () => {
				if (!Janus.isWebrtcSupported()) {
					reject('WebRTC is not supported!')
					return
				}
				log.log(`Janus version ${Janus.VERSION} initialized`)
				resolve()
			},
		})
	})
}

export async function getJanusNode(
	janusDefinition: JanusDefinition,
	userId: UserId,
	onFatalError: Callback
): Promise<JanusNode> {
	const janusConnectFatalError: Callback = onFatalError
	await initJanus()
	const node = new JanusNode(janusDefinition, userId, onFatalError)
	await node.connect(janusConnectFatalError)

	log.log(
		`Created Janus instance ${janusDefinition.janusNodeUrl} for room ${janusDefinition.janusVcrId}`
	)

	return node
}
type ListParticipantsResponse = {
	videoroom: 'participants'
	room: string | number
	participants: Array<JanusParticipant>
}
type JanusParticipant = {
	id: string | number
	display: string
	talking: boolean
}
