import { FunctionComponent } from 'react'
import { UserRole } from 'common/types'
import { AudioVideoConferenceMedia } from 'video-conference-media/audio-video-conference-media'
import { useSelector } from 'react-redux'
import { RootState } from 'common/redux'

export const ConfiguredAudioVideoConferenceMedia: FunctionComponent = () => {
	const userRole = useSelector((state: RootState) => state.workflow.user?.role)
	const roomUsers = useSelector((state: RootState) => state.room.users)

	if (userRole === UserRole.UPPER_ECHELON) {
		return <AudioVideoConferenceMedia spotlightPresenter={true} />
	} else if (userRole === UserRole.END_USER) {
		const presenter = roomUsers.find(u => u.role === UserRole.UPPER_ECHELON)
		return (
			<AudioVideoConferenceMedia
				spotlightPresenter={false}
				presenterId={presenter?.id}
			/>
		)
	} else {
		return null
	}
}
