import { FC, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentRoom } from './model/interaction-scheme'
import { TrainingRoom } from 'room/training-room/training-room'
import { WebinarRoom } from 'room/training-room/webinar-room'
import { translate } from 'intl/translate'
import { RootState } from 'common/redux'
import { setEventsSourcePath } from 'event-source-manager/redux'
import { EventsSourcePath, RoomAddress, RoomType, User } from 'common/types'
import { setJoinedRoom } from './redux'
import { GroupRoom } from 'room/group-room/group-room'
import { ConfiguredVideoConferenceMedia } from 'video-conference-media/configured-video-conference-media'
import { assertUnreachable } from 'utils/unreachable'
import { useJoinedRoomAddress } from 'room/common/use-joined-room-address'
import { useWorkflowUser } from 'room/common/use-workflow-user'
import { Lobby } from 'room/lobby'
import {
	ActivityHeartBeat,
	PresenceHeartBeat,
} from 'workflow/presentation/heart-beat'
import SystemNotifications from 'interaction-scheme/system-notifications'
import PublicChatRoom from 'room/public-chat'
import useInfoEventSource from 'session-info/use-session-info-source'
import { useAuthenticatedBeeldayClient } from 'connectivity/beelday-hooks'
import { JunoProvider } from '../vcr/juno/juno-provider'
import DevTools from 'room/common/presentation/devtools'
import { ProvideVideoComponents } from 'video-conference-media'
import { ConfiguredConferenceMedia } from 'video-conference-media/configured-conference-media'
import { ProvideAudioVideoComponents } from 'video-conference-media/audio-video-component-manager'

type DispatchedRoomProps = {
	address: RoomAddress
	user: User
}

const DispatchedRoom: FC<DispatchedRoomProps> = ({ address, user }) => {
	const beeldayClient = useAuthenticatedBeeldayClient()

	useInfoEventSource()

	const onBeat = useCallback(
		(secondsSinceLastActivity: number) =>
			beeldayClient.heartBeat(
				address.workflowId,
				user.id,
				secondsSinceLastActivity
			),
		[beeldayClient, address.workflowId, user.id]
	)

	switch (address.roomType) {
		case RoomType.GroupRoom:
			return (
				<>
					<ActivityHeartBeat onBeat={onBeat} />
					<GroupRoom />
					<ConfiguredVideoConferenceMedia />
				</>
			)
		case RoomType.TrainingRoom:
			return (
				<>
					<ActivityHeartBeat onBeat={onBeat} />
					<TrainingRoom />
					<ConfiguredConferenceMedia />
				</>
			)
		case RoomType.WebinarRoom:
			return (
				<>
					<ActivityHeartBeat onBeat={onBeat} />
					<WebinarRoom />
					<ConfiguredVideoConferenceMedia />
				</>
			)
		case RoomType.Lobby:
			return (
				<>
					<PresenceHeartBeat onBeat={onBeat} />
					<Lobby />
				</>
			)

		case RoomType.PublicChat:
			return (
				<>
					<PresenceHeartBeat onBeat={onBeat} />
					<PublicChatRoom />
					<ConfiguredVideoConferenceMedia />
				</>
			)
		default:
			assertUnreachable('RoomType', address.roomType)
	}
}

export const TrainingInteractionScheme: FC = () => {
	const dispatch = useDispatch()
	const joinedRoomAddress = useJoinedRoomAddress()
	const latestInteractionSchemeEvent = useSelector(
		(state: RootState) => state.eventSource.latestInteractionSchemeEvent
	)
	const workflowUser = useWorkflowUser()

	useEffect(() => {
		if (latestInteractionSchemeEvent && workflowUser) {
			const newJoinedRoom = getCurrentRoom(
				latestInteractionSchemeEvent,
				workflowUser.id
			)
			if (newJoinedRoom && newJoinedRoom.roomId !== joinedRoomAddress?.roomId) {
				dispatch(setJoinedRoom(newJoinedRoom))
				dispatch(setEventsSourcePath(EventsSourcePath.room))
			}
		}
	}, [dispatch, joinedRoomAddress, latestInteractionSchemeEvent, workflowUser])

	if (joinedRoomAddress && workflowUser) {
		return (
			<JunoProvider>
				<ProvideAudioVideoComponents>
					<DispatchedRoom address={joinedRoomAddress} user={workflowUser} />
				</ProvideAudioVideoComponents>
				<SystemNotifications />
				<DevTools />
			</JunoProvider>
		)
	}
	return <div>{translate('loading')}</div>
}
