import { arrays } from '@beelday/common'
import type { UserId } from 'common/types'
import type { SceneVideoUser, VideoUser } from './types'
import { User } from '@sentry/react'
import { difference, minBy } from 'lodash'

export type SceneProps = {
	fixedPresenterId?: UserId
	localUser?: VideoUser
	spotlightPresenter: boolean
}

const equals = (left: any, right: any): boolean =>
	!Object.entries(left).find(e => !(e[0] in right) || right[e[0]] !== e[1])

type SpotlightUser = {
	userId: UserId
	startedTalking: number
	stoppedTalking?: number
}

export type Config = {
	presenterDelay: number
	presenterCloseDelay: number
	trainerId: UserId
	// users: User[]
}

const DEFAULT_CONFIG = {
	presenterDelay: 3000,
	presenterCloseDelay: 6000,
}

export default class AudioVideoScene {
	private presenterDelay: number
	private presenterCloseDelay: number
	private spotlightChangeTimeout?: NodeJS.Timeout
	private onSpotlightChange?: (spotlight: UserId | undefined) => unknown
	private configureVideo?: (userId: UserId, video: boolean) => Promise<unknown>
	private saveActiveUsersToStorage?: (
		activeUsers: Array<{ userId: UserId; activity: number }>
	) => unknown
	private loadAcitveUsersFromStorage?: () => Array<{
		userId: UserId
		activity: number
	}>
	private talkingUsers: Map<UserId, SpotlightUser> = new Map()
	private spotlightUser?: SpotlightUser
	private props: SceneProps | undefined
	private remoteUsers: VideoUser[] = []
	private sceneVideoUsers: SceneVideoUser[] = []
	private outsideSceneAudioUsers: SceneVideoUser[] = []
	private muted = false
	private cachedSceneVideoUsers: Record<UserId, SceneVideoUser> = {}
	private trainerId?: string
	private users?: User[] = []
	private activeUsers: Array<{ userId: UserId; activity: number }> = []
	private sceneSize = 4

	constructor(
		options: Partial<Config>,
		configureVideo?: (userId: UserId, video: boolean) => Promise<unknown>,
		saveActiveUsersToStorage?: (
			activeUsers: Array<{
				userId: UserId
				activity: number
			}>
		) => unknown,
		loadAcitveUsersFromStorage?: () => Array<{
			userId: UserId
			activity: number
		}>,
		onSpotlightChange?: (spotlight: UserId | undefined) => unknown
	) {
		const config = { ...DEFAULT_CONFIG, ...options }
		this.onSpotlightChange = onSpotlightChange
		this.presenterDelay = config.presenterDelay
		this.presenterCloseDelay = config.presenterCloseDelay
		this.trainerId = config.trainerId
		this.saveActiveUsersToStorage = saveActiveUsersToStorage
		this.loadAcitveUsersFromStorage = loadAcitveUsersFromStorage
		this.configureVideo = configureVideo
		this.activeUsers = this.loadAcitveUsersFromStorage?.() || []
	}

	private handleSpotlightChange(): void {
		if (this.isSpotlightQuietFor(this.presenterDelay)) {
			const longestTalking = arrays.minBy(
				this.talkingUsers.values(),
				u => u.startedTalking
			)
			if (longestTalking) {
				this.spotlightUser = longestTalking
			} else if (this.isSpotlightQuietFor(this.presenterCloseDelay)) {
				this.spotlightUser = undefined
			} else {
				//Wait few more seconds before removing spotlight if everyone is quiet
				setTimeout(() => this.handleSpotlightChange(), this.presenterCloseDelay)
			}
		}

		const userStream = this.remoteUsers.find(
			u => u.userId === this.spotlightUser?.userId
		)

		if (
			this.spotlightUser &&
			userStream &&
			userStream.stream &&
			userStream.stream.getVideoTracks().length === 0 &&
			userStream.status.video !== 'RENEGOTIATING'
		) {
			this.configureVideo?.(this.spotlightUser?.userId, true)
		}

		this.setScene()
		this.onSpotlightChange?.(this.spotlightUser?.userId)
	}

	setUsers = (users: User[]): void => {
		if (this.users?.length === 0) {
			this.users = users
			this.setScene()
		} else if (this.users?.length !== users.length) {
			this.users = users
			this.setScene()
		}
	}

	addSpotlight = (userId: UserId): void => {
		const isTrainer = this.trainerId === this.props?.localUser?.userId
		const current = this.talkingUsers.get(userId)
		const activeUser = this.activeUsers.find(u => u.userId === userId)

		if (activeUser) {
			const index = this.activeUsers.findIndex(u => u.userId === userId)

			this.activeUsers.splice(index, 1, {
				...activeUser,
				activity: Date.now(),
			})
		} else if (
			this.activeUsers.length >=
			this.sceneSize - (isTrainer ? 1 : 2)
		) {
			const minActiveUser = minBy(this.activeUsers, u => u.activity)
			const index = this.activeUsers.findIndex(
				u => u.userId === minActiveUser?.userId
			)

			this.activeUsers.splice(index, 1, {
				userId: userId,
				activity: Date.now(),
			})
		} else {
			this.activeUsers.unshift({ userId, activity: Date.now() })
		}

		this.saveActiveUsersToStorage?.(this.activeUsers)

		if (current) {
			if (current.stoppedTalking) {
				current.stoppedTalking = undefined
			}
		} else {
			this.talkingUsers.set(userId, {
				userId: userId,
				startedTalking: Date.now(),
			})
		}

		if (this.spotlightUser?.userId === userId) {
			this.spotlightUser.stoppedTalking = undefined
		}

		this.handleSpotlightChange()
	}

	private isSpotlightQuietFor(ms: number): boolean {
		if (!this.spotlightUser) return true

		return this.spotlightUser.stoppedTalking
			? this.spotlightUser.stoppedTalking <= Date.now() - ms
			: false
	}

	removeSpotlight = (userId: UserId): void => {
		if (this.talkingUsers.delete(userId)) {
			if (this.spotlightUser?.userId === userId) {
				this.spotlightUser.stoppedTalking = Date.now()
				if (this.spotlightChangeTimeout) {
					clearTimeout(this.spotlightChangeTimeout)
				}
				this.spotlightChangeTimeout = setTimeout(
					() => this.handleSpotlightChange(),
					this.presenterDelay
				)
			}

			this.handleSpotlightChange()
		}
	}

	setProps = (
		props: SceneProps
	): {
		sceneUsers: SceneVideoUser[]
	} => {
		this.props = props
		this.setScene()
		return this.allUsers
	}

	setRemoteUsers = (
		remoteUsers: VideoUser[]
	): {
		sceneUsers: SceneVideoUser[]
	} => {
		this.remoteUsers = remoteUsers
		this.setScene()

		return this.allUsers
	}

	setScene = (): void => {
		const userStreamByActivity: Array<SceneVideoUser> = []

		const current = this.recalculatedSceneVideoUsers

		this.sceneVideoUsers = []
		this.outsideSceneAudioUsers = []

		const screenSharer = current.find(user => !!user.stream?.screenShareTrackId)

		const trainer = current.find(user => user.userId === this.trainerId)
		const presenter = current.find(
			user => user.presenter && user.userId !== this.trainerId
		)
		const me = current.find(
			user => user.userId === this.props?.localUser?.userId
		)

		if (screenSharer) {
			const activeScreenSharer = this.activeUsers.filter(
				u => u.userId === screenSharer.userId
			)[0]

			if (activeScreenSharer) {
				const index = this.activeUsers.findIndex(
					u => u.userId === activeScreenSharer?.userId
				)

				this.activeUsers.splice(index, 1, {
					userId: screenSharer.userId,
					activity: Date.now(),
				})
			} else {
				const minActiveUser = minBy(this.activeUsers, u => u.activity)
				const index = this.activeUsers.findIndex(
					u => u.userId === minActiveUser?.userId
				)

				this.activeUsers.splice(index, 1, {
					userId: screenSharer.userId,
					activity: Date.now(),
				})
			}

			this.saveActiveUsersToStorage?.(this.activeUsers)
		}

		const userIds = this.users?.map(u => u.id) || []

		const currentWithoutTrainerAndPresenterAndMeAndScreenSharer = current
			.filter(u => u.userId !== this.trainerId)
			.filter(u => u.userId !== presenter?.userId)
			.filter(u => u.userId !== me?.userId)
			.filter(u => u.userId !== screenSharer?.userId)

		const usersWithoutTrainerAndPresenterAndMeAndScreenSharer = userIds
			?.filter(u => u !== this.trainerId)
			.filter(u => u !== presenter?.userId)
			.filter(u => u !== me?.userId)
			.filter(u => u !== screenSharer?.userId)
			.filter(arrays.notEmpty)

		this.activeUsers?.forEach(({ userId }) => {
			const stream = currentWithoutTrainerAndPresenterAndMeAndScreenSharer.find(
				u => u.userId === userId
			)

			if (stream) {
				userStreamByActivity.push(stream)
			}
		})

		const diff = difference(
			userIds,
			this.activeUsers.map(u => u.userId)
		).filter(arrays.notEmpty)

		diff?.forEach(userId => {
			const stream = currentWithoutTrainerAndPresenterAndMeAndScreenSharer.find(
				u => u.userId === userId
			)

			if (stream) {
				userStreamByActivity.push(stream)
			}
		})

		if (screenSharer) {
			this.sceneVideoUsers.push({ ...screenSharer, isOnScene: true })
		}

		if (
			me &&
			this.props?.localUser?.userId !== this.trainerId &&
			this.props?.localUser?.userId !== presenter?.userId
		) {
			if (screenSharer?.userId === me.userId) {
			} else {
				this.sceneVideoUsers.push({ ...me, isOnScene: true })
			}
		}

		if (trainer) {
			if (screenSharer?.userId === trainer.userId) {
			} else {
				this.sceneVideoUsers.push({ ...trainer, isOnScene: true })
			}
		}

		if (presenter) {
			if (screenSharer?.userId === presenter.userId) {
			} else {
				this.sceneVideoUsers.push({ ...presenter, isOnScene: true })
			}
		}

		userStreamByActivity.forEach(user => {
			if (this.sceneVideoUsers.length >= this.sceneSize) {
				this.outsideSceneAudioUsers.push({ ...user, isOnScene: false })
			} else if (
				user.status.video !== 'READY' &&
				user.stream?.getVideoTracks().length === 0
			) {
				this.outsideSceneAudioUsers.push({ ...user, isOnScene: false })
			} else {
				const cached = this.cachedSceneVideoUsers[user.userId]
				if (!cached || !equals(user, cached)) {
					this.cachedSceneVideoUsers[user.userId] = user
					this.sceneVideoUsers.push({ ...user, isOnScene: true })
				} else {
					this.sceneVideoUsers.push({ ...cached, isOnScene: true })
				}
			}
		})
		console.log(
			'CURRENT',
			[...current],
			'usersWithoutTrainerAndPresenterAndMe',
			[...usersWithoutTrainerAndPresenterAndMeAndScreenSharer],
			'userStreamByActivity',
			[...userStreamByActivity],
			'sceneVideoUsers',
			[...this.sceneVideoUsers]
		)
	}

	setSceneMuted = (
		muted: boolean
	): {
		sceneUsers: SceneVideoUser[]
	} => {
		this.muted = muted
		this.setScene()
		return this.allUsers
	}

	private isPresenter = (userId: UserId) =>
		this.presenterUserId === userId &&
		this.presenterUserId !== this.props?.localUser?.userId

	private isTalking = (userId: string) => this.talkingUsers.has(userId)

	private toRemoteSceneVideoUser = (videoUser: VideoUser): SceneVideoUser => {
		const isPresenter =
			this.isPresenter(videoUser.userId) &&
			videoUser.status.video !== 'RENEGOTIATING'
		return {
			...videoUser,
			spotlight: this.isTalking(videoUser.userId),
			presenter: isPresenter,
			muted: this.muted,
			isSelfView: false,
			status: videoUser.status,
		}
	}

	private toLocalSceneVideoUser = (videoUser: VideoUser): SceneVideoUser => {
		const isPresenter =
			this.isPresenter(videoUser.userId) &&
			videoUser.status.video !== 'RENEGOTIATING'
		return {
			...videoUser,
			spotlight: this.isTalking(videoUser.userId),
			presenter: isPresenter,
			muted: true,
			isSelfView: true,
			status: videoUser.status,
		}
	}

	private get recalculatedSceneVideoUsers(): SceneVideoUser[] {
		if (!this.props) {
			return []
		}

		const remoteSceneVideoUsers = this.remoteUsers.map(
			this.toRemoteSceneVideoUser
		)
		if (this.props.localUser) {
			return [
				...remoteSceneVideoUsers,
				this.toLocalSceneVideoUser(this.props.localUser),
			]
		}
		return remoteSceneVideoUsers
	}

	get allUsers(): {
		sceneUsers: SceneVideoUser[]
	} {
		return {
			sceneUsers: [...this.sceneVideoUsers, ...this.outsideSceneAudioUsers],
		}
	}

	private get presenterUserId() {
		if (!this.props) return undefined

		const { fixedPresenterId, spotlightPresenter } = this.props
		const availableUserIds = this.remoteUsers.map(user => user.userId)

		if (fixedPresenterId && availableUserIds.includes(fixedPresenterId)) {
			return fixedPresenterId
		}

		if (spotlightPresenter) {
			return this.spotlightUser?.userId
		}
	}
}
