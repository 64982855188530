import { FunctionComponent } from 'react'
import { useSelector } from 'app-redux'
import { selectTrainingType } from 'session-info/redux'
import { ConfiguredAudioVideoConferenceMedia } from './configured-audio-video-conference-media'
import { ConfiguredVideoConferenceMedia } from './configured-video-conference-media'

export const ConfiguredConferenceMedia: FunctionComponent = () => {
	const sessionType = useSelector(state =>
		selectTrainingType(state.sessionInfo)
	)

	if (!sessionType) {
		return null
	}

	if (sessionType === 'BIG_ONLINE') {
		return <ConfiguredAudioVideoConferenceMedia />
	} else {
		return <ConfiguredVideoConferenceMedia />
	}
}
