import { UserProfile } from 'common/types'
import { keyBy, map, omit } from 'lodash'
import { RemoteUserStream, VideoUser } from './types'
import { logger } from '@beelday/common'

const log = logger.create('video-conference-media')

export const toVideoUsers = (
	remoteStreams: RemoteUserStream[],
	users: UserProfile[]
): VideoUser[] => {
	const streamsByUserId = keyBy(remoteStreams, 'userId')

	const usersWithStreams = users
		.map(u => {
			const remoteStream = streamsByUserId[u.id]

			return {
				userId: u.id,
				userName: u.displayName || u.name,
				stream: remoteStream && remoteStream.stream,
				status: remoteStream && remoteStream.status,
			}
		})
		.filter(u => u.stream)

	if (usersWithStreams.length < remoteStreams.length) {
		const userIds = map(users, 'id')
		remoteStreams.forEach(s => {
			if (!userIds.includes(s.userId)) {
				log.warn(`Remote stream ${s.feedId} is not associated with any user`)
				usersWithStreams.push({
					userId: s.userId,
					stream: s.stream,
					userName: '--Unknown--',
					status: s.status,
				})
			}
		})
	}
	return usersWithStreams
}
