import { arrays, UUID } from '@beelday/common'
import { RootState } from 'common/redux'
import {
	FeaturesChangedEvent,
	FeatureType,
	FocusedFeatureTypes,
	FocusFeature,
	isRoomAddress,
	RoomAddress,
	TrainingRoomPhase,
	User,
	UserRole,
} from 'common/types'
import { first } from 'lodash'
import { createSelector } from 'reselect'
import { getEndUsers } from './model/room'

export type ShowDraggableStream = boolean
export type DraggableStreamPosition = { x: number; y: number }

export enum MiscSelectedSelectedItem {
	Agenda,
	Messages,
	Boardlet,
}

const roomReducerDefaultState: RoomState = {
	users: [],
	enabledFeatures: [],
	trainingRoomPhase: TrainingRoomPhase.INITIAL,
	draggableStreamPosition: {
		x: 0,
		y: 0,
	},
	miscPanel: {
		selectedItem: MiscSelectedSelectedItem.Agenda,
		isOpen: false,
	},
	screenShare: {
		user: undefined,
		stopShare: false,
	},
}

export interface RoomState {
	enabledFeatures: FeatureType[]
	users: User[]
	trainingRoomPhase: TrainingRoomPhase
	draggableStreamPosition?: DraggableStreamPosition
	miscPanel: {
		selectedItem: MiscSelectedSelectedItem
		isOpen: boolean
	}
	screenShare: {
		user?: UUID
		stopShare: boolean
	}
}

type RoomAction =
	| { type: 'SET_ROOM_USERS'; users: User[] }
	| { type: 'SET_TRAINING_ROOM_PHASE'; trainingRoomPhase: TrainingRoomPhase }
	| { type: 'SSE_POLLING_STARTED' }
	| { type: 'SSE_POLLING_REVIEW_STARTED' }
	| { type: 'SSE_POLLING_CLOSED' }
	| { type: 'SSE_SCREEN_SHARING_STARTED'; payload: { user: UUID } }
	| { type: 'SSE_SCREEN_SHARING_STOPPED'; payload: { user: UUID } }
	| { type: 'SET_SHOW_DRAGGABLE_STREAM'; show: boolean }
	| {
			type: 'SET_DRAGGABLE_POSITION'
			draggableStreamPosition: DraggableStreamPosition
	  }
	| {
			type: 'SET_MISC_PANEL_OPEN'
			isOpen: boolean
	  }
	| {
			type: 'SET_MISC_PANEL_SELECTED_ITEM'
			selectedItem: MiscSelectedSelectedItem
	  }
	| {
			type: 'SSE_FEATURE_FOCUSED'
			payload: {
				focusedFeatures: FocusFeature[]
			}
	  }
	| {
			type: 'SSE_FEATURES_CHANGED'
			payload: FeaturesChangedEvent
	  }

export const setRoomUsers = (users: User[]): RoomAction => {
	return { type: 'SET_ROOM_USERS', users }
}

export const setTrainingRoomPhase = (
	trainingRoomPhase: TrainingRoomPhase
): RoomAction => {
	return { type: 'SET_TRAINING_ROOM_PHASE', trainingRoomPhase }
}

export const setDraggablePosition = (
	draggableStreamPosition: DraggableStreamPosition
): RoomAction => {
	return { type: 'SET_DRAGGABLE_POSITION', draggableStreamPosition }
}

export const setMiscPanelOpen = (isOpen: boolean): RoomAction => {
	return { type: 'SET_MISC_PANEL_OPEN', isOpen }
}

export const setMiscPanelSelectedItem = (
	selectedItem: MiscSelectedSelectedItem
): RoomAction => {
	return { type: 'SET_MISC_PANEL_SELECTED_ITEM', selectedItem }
}

export function roomReducer(
	state: RoomState = roomReducerDefaultState,
	action: RoomAction
): RoomState {
	switch (action.type) {
		case 'SET_ROOM_USERS':
			if (!state.users || state.users.length === 0) {
				return {
					...state,
					users: action.users,
				}
			}
			if (state.users.length < action.users.length) {
				const newUser = action.users.find(
					u => !state.users.find(u2 => u2.id === u.id)
				)
				const newArrayOfUsers = [...state.users, newUser].filter(
					arrays.notEmpty
				)
				return {
					...state,
					users: newArrayOfUsers,
				}
			} else if (state.users.length > action.users.length) {
				const newArrayOfUsers = state.users.filter(
					u => !!action.users.find(au => u.id === au.id)
				)
				return {
					...state,
					users: newArrayOfUsers,
				}
			} else {
				return {
					...state,
				}
			}
		case 'SET_TRAINING_ROOM_PHASE':
			return {
				...state,
				trainingRoomPhase: action.trainingRoomPhase,
			}
		case 'SET_DRAGGABLE_POSITION':
			return {
				...state,
				draggableStreamPosition: action.draggableStreamPosition,
			}
		case 'SET_MISC_PANEL_OPEN':
			return {
				...state,
				miscPanel: { ...state.miscPanel, isOpen: action.isOpen },
			}
		case 'SET_MISC_PANEL_SELECTED_ITEM':
			return {
				...state,
				miscPanel: { ...state.miscPanel, selectedItem: action.selectedItem },
			}
		case 'SSE_SCREEN_SHARING_STARTED':
			return {
				...state,
				screenShare: {
					user: action.payload.user,
					stopShare: false,
				},
			}
		case 'SSE_SCREEN_SHARING_STOPPED':
			if (state.screenShare.user === action.payload.user) {
				return {
					...state,
					screenShare: {
						user: undefined,
						stopShare: true,
					},
				}
			} else {
				return state
			}
		case 'SSE_FEATURES_CHANGED':
			if (isRoomAddress(action.payload.context)) {
				return {
					...state,
					enabledFeatures: action.payload.enabled,
				}
			} else {
				return state
			}
		default:
			return state
	}
}

export const selectTrainerStream = createSelector(
	(state: RootState) => state.room.users,
	(state: RootState) => state.videoConferenceMedia.sceneVideoUsers,
	(users, sceneVideoUsers) =>
		first(
			users
				.filter(u => u.role === UserRole.UPPER_ECHELON)
				.map(u => sceneVideoUsers.find(s => s.userId === u.id))
		)
)

export const selectAllUsers = (state: RootState): User[] => state.room.users

export const roomEndUsers = createSelector(
	(state: RootState) => state.room.users,
	users => getEndUsers(users)
)

export const roomUserIds = createSelector(
	(state: RootState) => state.room.users,
	users => users.map(u => u.id)
)

export const screenShareUserId = (state: RootState): string | undefined =>
	state.room.screenShare.user

export const stopShare = (state: RootState): boolean =>
	state.room.screenShare.stopShare

export const isScreenSharingActive = (state: RootState): boolean =>
	state.trainingRoom.focusedFeatures[0] &&
	state.trainingRoom.focusedFeatures[0].type === FocusedFeatureTypes.ScreenShare

export const selectJoinedRoomAddress = createSelector(
	(state: RootState) => state.workflow.workflowId,
	(state: RootState) =>
		state.workflow.joinedInteractionScheme?.interactionSchemeId,
	(state: RootState) => state.interactionScheme.joinedRoom,
	(workflowId, isId, room): RoomAddress | undefined => {
		if (workflowId && isId && room) {
			return {
				workflowId: workflowId,
				interactionSchemeId: isId,
				roomId: room?.roomId,
				roomType: room.roomType,
			}
		} else {
			return undefined
		}
	}
)

export const selectEnabledFeatures = (state: RootState): FeatureType[] => [
	...state.room.enabledFeatures,
	...state.interactionScheme.enabledFeatures,
]
